<template>
    <div>
        <div class="row">
            <div class="col">
                <h3>
                    UPGRADE CANVAS
                    <InfoPopoverComponent
                        title="Slim vs. Thick Canvas"
                    >
                        <p>
                            The <b>Slim Canvas</b> has a 0.75
                            inch wooden frame, offering a sleek,
                            minimalist look.
                        </p>
                        <p><br /></p>
                        <p>
                            The <b>Thick Canvas</b> has a
                            1.25-1.5 inch frame, providing a
                            bolder, more substantial presence.
                        </p>
                    </InfoPopoverComponent>
                </h3>
            </div>
        </div>
        <div class="row options">
            <div
                class="col"
                v-for="option in availableCanvasOptions"
                :key="option.label"
            >
                <a
                    @click.prevent="
                                        selectOption(option)
                                    "
                    href="#"
                    class="option"
                    ref="canvas_option"
                    :class="{
                                        selected: option.selected,
                                        disabled: option.disabled,
                                        'canvas-slim': option.label === 'slim',
                                        'canvas-thick':
                                            option.label === 'thick',
                                    }"
                >
                    <p>
                        {{
                            capitalizeFirstLetter(
                                snakeCaseToSpace(option.label)
                            )
                        }}
                    </p>
                    <p>
                        {{ formatCanvasPrice(option) }}
                    </p>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import InfoPopoverComponent from "@/components/InfoPopoverComponent.vue";
import { useOrderStore } from "@/stores/order";
import { capitalizeFirstLetter, snakeCaseToSpace } from "../../../utilities/strings";
import { currencySymbol } from "@/utilities/currency";
import { useCartStore } from "@/stores/cart";

export default {
    name: "CanvasUpgrade",
    components: { InfoPopoverComponent },
    props: {
        order: Object,
    },
    data() {
        return {
            canvas_options: [],
            orderStore: useOrderStore(),
            cartStore: useCartStore(),
        };
    },
    computed: {
        availableCanvasOptions() {
            let options = this.canvas_options.filter((o) => o.available);

            return options.length > 1 ? options : [];
        },
    },
    mounted() {
        let availableCanvasUpgrades =
            this.orderStore.order_data.available_upgrades.canvas_style;

        if (availableCanvasUpgrades) {
            for (let i = 0; i < availableCanvasUpgrades.length; i++) {
                let elm = Object.assign({}, availableCanvasUpgrades[i]);

                elm.current = false;
                elm.selected = false;
                elm.available = true;
                elm.label = elm.canvas_style;

                if (
                    !!this.order.canvas &&
                    this.order.canvas !== "slim" &&
                    elm.canvas_style === "slim"
                ) {
                    continue;
                } else if (
                    elm.canvas_style === this.order.canvas ||
                    ((!this.order.canvas || this.order.canvas === "slim") &&
                        elm.canvas_style === "slim")
                ) {
                    elm.current = true;
                    elm.selected = true;
                }

                this.canvas_options.push(elm);
            }
        }
    },
    unmounted() {
        this.$emit("canvasUpdate", 0);
    },
    methods: {
        snakeCaseToSpace, capitalizeFirstLetter,
        formatCanvasPrice(option) {
            let value = this.getCanvasUpgradeValue(option);

            if (option.current) {
                return "Current";
            }

            return (
                "+" +
                currencySymbol(option.currency) +
                value * this.order.quantity
            );
        },
        getCanvasUpgradeValue(option) {
            let current = this["canvas_options"]?.find((elm) => elm.current);

            return option.amount - (current?.amount || 0);
        },
        selectOption(option) {
            this.canvas_options.forEach((elm) => {
                elm.selected = elm.label === option.label;

                if (elm.selected) {
                    if (elm.is_void) {
                        this.cartStore.removeFromCart("canvas");
                    } else {
                        this.cartStore.addToCart(this.order, {
                            canvas: elm,
                        });
                    }

                    this.$emit("canvasUpdate", elm.amount * this.order.quantity);
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
h3 {
    margin: 16px 0 8px;
    font-size: 14px;
    line-height: 20px;
    color: #363636;
}

.col {
    padding-left: 6px;
    padding-right: 6px;
}

a.option {
    text-align: center;
    color: #392f5a;
    background-color: #ebeaee;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    display: block;
    padding: 8px 14px;
    outline: 1px solid #dad8e0;
    border-radius: 4px;

    &:hover {
        text-decoration: none;
    }

    &.selected {
        outline: 2px solid #392f5a;
    }

    &.disabled {
        cursor: default;
        background: lighten(#ebeaee, 10%);
        outline-color: lighten(#8d8b88, 2%);
        color: lighten(#9084b5, 2%);
    }

    &.canvas-slim {
        background-image: url("@/assets/images/upsells/canvas/slim_canvas.png");
        background-size: 33%;
        background-position: right -1px bottom;
        background-repeat: no-repeat;
        padding-right: 25px;
    }

    &.canvas-thick {
        background-image: url("@/assets/images/upsells/canvas/thick_canvas.png");
        background-size: 33%;
        background-position: right bottom;
        background-repeat: no-repeat;
        padding-right: 35px;
    }

    p {
        margin: 5px 0;
    }
}
</style>